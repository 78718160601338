const getCoords = (coords, i) => {
  let res = coords

  for (let j = 0; j < i; j++) {
    res = res[0]
  }

  return res
}

const applyCoordsChanges = (coords, cb, i) => {
  if (!coords?.length) return

  if (Array.isArray(coords?.[0])) {
    i++

    coords.forEach(coord => {
      applyCoordsChanges(coord, cb, i)
    })
  } else {
    cb(i)
  }
}

export const setCoordsCloser = coords => {
  if (!coords?.length) return

  let ind = 0

  const cb = j => {
    const coordinates = getCoords(coords, j - 1)

    for (let i = 1; i < coordinates.length; i++) {
      const diff = coordinates[i][0] - coordinates[i - 1][0]

      if (diff > 180) {
        coordinates[i][0] -= 360
      } else if (diff < -180) {
        coordinates[i][0] += 360
      }
    }
  }

  applyCoordsChanges(coords, cb, ind)
}
