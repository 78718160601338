import {
  GET_TR_SIT_ROOT_ENDPOINT,
  GET_MODELING_ROOT_ENDPOINT,
  GET_DT_ROOT_ENDPOINT
} from '@/api/modeling'
import { modelingProjects } from './project-lists'

export default (beforeEnter, wrapper) => {
  return {
    name: 'system',
    path: 'system',
    redirect: 'explorer',
    beforeEnter,
    component: wrapper,
    meta: {
      title: 'system',
      icon: 'settings',
      description: 'system:desc'
    },
    children: [
      {
        name: 'mrir',
        path: 'mrir',
        beforeEnter,
        component: () => import('@/modules/mrir/mrir'),
        meta: {
          title: 'mrir:title',
          admin_only: true,
          abbr: 'mrir:abbr-title',
          icon: 'users',
          description: 'mrir:desc'
        }
      },
      {
        name: 'system-monitoring',
        path: 'system-monitoring',
        meta: {
          abbr: 'system-monitoring:abbr-title',
          subtitle: 'system-monitoring:title',
          icon: 'chart-line',
          projects: ['demo', 'eputs']
        },
        beforeEnter,
        component: () => import('@/modules/system-monitoring')
      },
      {
        name: 'regulations',
        path: 'regulations',
        meta: {
          abbr: 'regulations:abbr-title',
          subtitle: 'regulations:title',
          icon: 'acts-document',
          projects: ['demo', 'eputs', 'rostov_on_don', 'surgut'],
          endpoints: ['road_works', 'permissions', 'traffic_violations']
        },
        beforeEnter,
        component: () => import('@/modules/regulations/regulations')
      },
      {
        name: 'transport-situation',
        path: 'transport-situation',
        meta: {
          // abbr: 'transport-situation:abbr-title',
          subtitle: 'transport-situation',
          icon: 'sort-unselected',
          projects: ['demo', 'eputs'],
          hiddenRoute: true,
          endpoints: [GET_TR_SIT_ROOT_ENDPOINT()],
          isMap: true
        },
        beforeEnter,
        component: () => import('@/modules/transport-situation/views')
      },
      {
        name: 'tsodd-secondary',
        path: 'tsodd-secondary',
        beforeEnter,
        component: wrapper,
        meta: {
          title: 'tsodd-secondary',
          icon: 'speed-limit',
          hiddenRoute: true,
          projects: ['demo', 'eputs'],
          endpoints: ['road_consulting']
        },
        redirect: 'tsodd-secondary/main',
        children: [
          {
            name: 'tsodd-secondary-main',
            path: 'main',
            beforeEnter,
            component: () => import('@/modules/tsodd-secondary/views/tsodd'),
            meta: { subtitle: 'tsodd', icon: 'speed-limit', isMap: true }
          },
          {
            name: 'tsodd-secondary-registry',
            path: 'registry',
            beforeEnter,
            component: () => import('@/modules/tsodd-secondary/views/registry'),
            meta: {
              title: 'tsodd-secondary',
              subtitle: 'tsodd-secondary:registry_title',
              icon: 'speed-limit'
            }
          }
        ]
      },
      {
        name: 'account',
        path: 'account',
        beforeEnter,
        component: () => import('@/modules/mrir/account'),
        meta: {
          title: 'account:title',
          abbr: 'account:abbr-title',
          icon: 'user'
        }
      },
      {
        name: 'profile',
        path: 'profile',
        beforeEnter,
        component: () => import('@/modules/user/profile/profile'),
        meta: {
          title: 'user:profile_title',
          abbr: 'user:abbr-profile_title',
          icon: 'tasks'
        }
      },
      {
        name: 'activity-log',
        path: 'activity-log',
        beforeEnter,
        component: () => import('@/modules/user/activity-log/activity-log'),
        meta: {
          admin_only: true,
          title: 'user:activity-log',
          abbr: 'user:activity-log',
          icon: 'ksodd-tables'
        }
      },
      {
        name: 'ui',
        path: 'ui',
        beforeEnter,
        component: () => import('@/modules/ui-kit/ui-kit'),
        meta: {
          dev_only: true,
          title: 'UI компоненты',
          abbr: 'UI',
          icon: 'style-presets'
        }
      },
      {
        name: 'reports',
        path: 'reports',
        beforeEnter,
        component: () => import('@/modules/reports/reports'),
        meta: {
          admin_only: true,
          title: 'reports:title',
          abbr: 'reports:title',
          icon: 'charts-general',
          projects: ['demo', 'eputs', 'tashkent']
        }
      },
      {
        name: 'attributes-manager',
        path: 'attributes-manager',
        beforeEnter,
        component: () =>
          import('@/modules/attributes-manager/attributes-manager'),
        meta: {
          endpoints: [GET_MODELING_ROOT_ENDPOINT(), GET_DT_ROOT_ENDPOINT()],
          title: 'attributes_manager:title',
          abbr: 'attributes_manager:title',
          icon: 'attribute-class',
          projects: [...modelingProjects]
        }
      },
      {
        name: 'notifications',
        path: 'notifications',
        beforeEnter,
        component: () => import('@/modules/notifications/views'),
        meta: {
          title: 'notifications',
          abbr: 'notifications',
          icon: 'notification',
          hiddenRoute: true
        }
      },
      {
        name: 'test-requests',
        path: 'test-requests',
        beforeEnter,
        component: () => import('@/modules/test-requests/test-requests'),
        meta: {
          dev_only: true,
          title: 'Тестирование запросов на фронте',
          abbr: 'Тестирование запросов',
          icon: 'send-msg'
        }
      },
      {
        name: 'enum-registry',
        path: 'enum-registry',
        beforeEnter,
        component: () => import('@/modules/enum-registry/enum-registry'),
        meta: {
          title: 'Реестр ЕНАМ',
          abbr: 'ЕНАМ',
          icon: 'info-screen-1',
          projects: ['vladimir']
        }
      },
      {
        name: 'enum-external-registry',
        path: 'enum-external-registry',
        beforeEnter() {
          location.href = 'http://enum.registries.exampler.loc' // Might be a problem in vue3
        },
        meta: {
          title: 'Реестр идентификаторов ENUM',
          abbr: 'Реестр ENUM',
          icon: 'info-screen-1',
          projects: ['rostov_on_don']
        }
      },
      {
        name: 'service-external-registry',
        path: 'service-external-registry',
        beforeEnter() {
          location.href = 'http://svc.registries.exampler.loc' // Might be a problem in vue3
        },
        meta: {
          title: 'Панель реестра сервисов',
          abbr: 'Реестр сервисов',
          icon: 'list',
          projects: ['rostov_on_don']
        }
      },
      {
        name: 'settings',
        path: 'settings',
        beforeEnter,
        component: () => import('@/modules/settings/settings'),
        meta: {
          title: 'settings',
          abbr: 'settings',
          icon: 'settings'
        }
      },
      {
        name: 'mailing',
        path: 'mailing',
        beforeEnter,
        component: () => import('@/modules/mailing/mailing'),
        meta: {
          title: 'mailing',
          abbr: 'mailing',
          icon: 'email-notification'
        }
      },
      {
        name: 'source-access',
        path: 'source-access',
        beforeEnter,
        component: () => import('@/modules/source-access/source-access'),
        meta: {
          title: 'source-access:title',
          abbr: 'source-access:title',
          icon: 'user-shared'
        }
      }
    ]
  }
}
