export const modelingProjects = [
  'habarovsk_akadem',
  'habarovsk_tpim',
  'gbu_bdd_kazan',
  'rostov_on_don',
  'spb_politekh',
  'vladikavkaz',
  'rostov_tmp',
  'cheboksary',
  'vladimir',
  'tashkent',
  'academic',
  'loadtest',
  'spb_gasu',
  'avtodor',
  'labgrad',
  'cpt_spb',
  'kubgtu',
  'invest',
  'irano',
  'urgups',
  'uunit',
  'niipi',
  'eputs',
  'tomsk',
  'vshe',
  'demo',
  'urfu',
  'lgtu',
  'guap',
  'madi',
  'omsk',
  'guu',
  'kdh',
  'ctt',
  'vtm',
  'tiu'
]

export const gisProjects = [
  'habarovsk_akadem',
  'habarovsk_tpim',
  'habarovsk_gis',
  'rostov_on_don',
  'gbu_bdd_kazan',
  'spb_politekh',
  'vladikavkaz',
  'rostov_tmp',
  'cheboksary',
  'rut_miit',
  'tashkent',
  'academic',
  'loadtest',
  'vladimir',
  'brusnika',
  'spb_gasu',
  'minimal',
  'labgrad',
  'instroj',
  'cpt_spb',
  'avtodor',
  'kubgtu',
  'invest',
  'ryazan',
  'surgut',
  'urgups',
  'irano',
  'tagil',
  'eputs',
  'tomsk',
  'uunit',
  'niipi',
  'vshe',
  'madi',
  'lgtu',
  'demo',
  'urfu',
  'omsk',
  'guap',
  'kdh',
  'guu',
  'ctt',
  'tiu',
  'vtm',
  'mgu',
  'mmk'
]
